<!--
 * @ Author: Clark
 * @ Create Time: 2023-02-05 20:20:57
 * @ Modified by: Your name
 * @ Modified time: 2023-03-03 16:48:21
 * @ Description: 商户管理>平台商户>平台商户列表  列表 新增 编辑 没有问题
 -->

<template>
  <scroll-layout>
    <template slot="header">
      <ykc-search
        ref="YkcSearch"
        :data="searchData"
        :searchForm="searchParams"
        @click="handleSearchConfirm"
        @clean="handleSearchClean"></ykc-search>
    </template>
    <div class="motorcade-main-account">
      <ykc-table
        ref="YkcTable"
        :data="tableData"
        :columns="tableColumns"
        :title="tableTitle"
        operateFixedType="right"
        :selectable="false"
        :selectOnIndeterminate="false"
        @selection-change="selectionChange"
        :rowSelectable="row => ['1', '2'].includes(row.status)"
        :operateButtons="tableOperateButtons">
        <template #headerButtons>
          <div class="table-header-button">
            <template v-for="(btn, i) in tableButtons">
              <ykc-button :type="btn.type" v-if="btn.enabled()" :key="i" @click="btn.handler">
                {{ btn.label }}
              </ykc-button>
            </template>
          </div>
        </template>
        <ykc-pagination
          slot="pagination"
          ref="YkcPagination"
          :total="pageInfo.total"
          :current-page.sync="pageInfo.current"
          @current-change="handlePaginationCurrentChange"
          :page-size.sync="pageInfo.size"
          @size-change="handlePaginationSizeChange"></ykc-pagination>
      </ykc-table>
    </div>
    <ykc-drawer
      :show.sync="showDrawer"
      :title="drawerTitle"
      :on-close="onAddDrawerClose"
      :before-cancel="onAddDrawerCancel"
      ensure-txt="保存">
      <template #footer>
        <div class="drawer-button-wrap">
          <template v-for="(btn, i) in drawerButtons">
            <ykc-button :type="btn.type" v-if="btn.enabled()" :key="i" @click="btn.handler">
              {{ btn.label }}
            </ykc-button>
          </template>
        </div>
      </template>
      <div class="drawer-main-body" v-if="showDrawer">
        <AddOrEdit
          v-if="drawerType === 'edit' || drawerType === 'add'"
          ref="addOrEdit"
          :isEdit="isEdit"
          :id="id"></AddOrEdit>
      </div>
    </ykc-drawer>
  </scroll-layout>
</template>

<script>
  import YkcDialog from '@/components/ykc-ui/dialog/index';
  import AddOrEdit from './AddOrEdit.vue';
  // import ImportData from './ImportData.vue';
  import { investmentData } from '@/service/apis';
  import { offlineExport, code } from '@/utils';

  export default {
    name: 'MotorcadeMainAccountList',
    components: {
      AddOrEdit,
      // ImportData,
    },
    data() {
      return {
        maxStepIndex: 1,
        selectIds: [],
        id: 0,
        cityList: [],
        dictionary: {},
        drawerType: '',
        willEndCount: 0,
        listType: '0',
        stepsIndex: 0,
        maxIndex: 2,
        drawerButtons: [
          {
            label: '取消',
            type: 'plain',
            enabled: () => true,
            handler: () => {
              YkcDialog({
                dialogType: 'feedback',
                showTitle: false,
                showFooter: true,
                desc: '取消后操作将不被保存，确认是否取消？',
                onCancel: dialogDone => {
                  dialogDone(); // 隐藏弹窗
                },
                onConfirm: dialogDone => {
                  dialogDone(); // 隐藏弹窗
                  this.showDrawer = false; // 隐藏抽屉
                },
              });
            },
          },
          // {
          //   label: '上一步',
          //   enabled: () => {
          //     return this.stepsIndex !== 0;
          //   },
          //   disabled: () => false,
          //   handler: () => {
          //     this.stepsIndex--;
          //   },
          // },
          // {
          //   label: '下一步',
          //   enabled: () => {
          //     return this.stepsIndex !== this.maxStepIndex;
          //   },
          //   disabled: () => false,
          //   handler: () => {
          //     this.$refs.addOrEdit.validateForm().then(() => {
          //       this.stepsIndex++;
          //     });
          //   },
          // },
          {
            label: '保存',
            enabled: () => {
              // return this.stepsIndex === this.maxStepIndex;
              return true;
            },
            disabled: () => {
              return this.isRequest;
            },
            handler: () => {
              this.isRequest = true;
              this.$refs.addOrEdit
                .submitForm()
                .then(() => {
                  this.stepsIndex = 0;
                  this.showDrawer = false;
                  this.isRequest = false;
                  this.searchTableList();
                })
                .finally(() => {
                  this.isRequest = false;
                });
            },
          },
        ],
        tableButtons: [
          {
            label: '新增',
            type: 'plain',
            enabled: () => code('merchant:union:add'),
            handler: () => {
              this.add();
            },
          },
        ],

        tableData: [],

        tableColumns: [
          { label: '投资方名称', prop: 'investorName', minWidth: '150px' },
          { label: '运营商ID', prop: 'operatorId', minWidth: '200px' },
          { label: '设备所属方ID', prop: 'deviceId', minWidth: '200px' },
          { label: '联系人', prop: 'contacts', minWidth: '150px' },
          { label: '联系电话', prop: 'contactsPhone', minWidth: '150px' },
          { label: '公司地址', prop: 'companyAddress', minWidth: '150px' },
          { label: '公司电话', prop: 'companyPhone', minWidth: '150px' },
          { label: '开票名称', prop: 'invoicingName', minWidth: '150px' },
          { label: '收款银行', prop: 'bank', minWidth: '150px' },
          { label: '收款账号', prop: 'bankNo', minWidth: '150px' },
          { label: '开票地址', prop: 'invoicingAddress', minWidth: '150px' },
          { label: '税号', prop: 'taxNo', minWidth: '150px' },
          { label: '是否有效', prop: 'isEffective', minWidth: '150px' },
          { label: '创建日期', prop: 'createdTime', minWidth: '200px' },
          { label: '创建人', prop: 'createdBy', minWidth: '150px' },
          { label: '更新日期', prop: 'modifiedTime', minWidth: '200px' },
          { label: '更新人', prop: 'modifiedBy', minWidth: '150px' },
          { label: '微信商户', prop: 'mchName', minWidth: '150px' },
        ],
        tableTitle: '投资方列表',

        tableOperateButtons: [
          {
            render: (h, data, { row }) =>
              code('merchant:union:detail') ? (
                <ykc-button
                  type="text"
                  onClick={() => {
                    this.detailLink(row);
                  }}>
                  详情
                </ykc-button>
              ) : null,
          },
          {
            render: (h, data, { row }) =>
              code('merchant:union:edit') ? (
                <ykc-button
                  type="text"
                  onClick={() => {
                    this.maxStepIndex = 1;
                    this.stepsIndex = 0;
                    this.showAddDrawer = true;
                    this.isEdit = true;
                    this.id = row.id;
                    this.drawerType = 'edit';
                    this.showDrawer = true;
                  }}>
                  修改
                </ykc-button>
              ) : null,
          },
          // {
          //   render: (h, data, { row }) =>
          //     code('merchant:union:edit') ? (
          //       <ykc-button
          //         type="text"
          //         onClick={() => {
          //           this.remove(row);
          //         }}>
          //         删除
          //       </ykc-button>
          //     ) : null,
          // },
        ],
        pageInfo: {
          total: 0,
          current: 1,
          size: 10,
        },
        searchParams: {
          operatorType: '2', // 互联商户
          name: '',
          socialCreditCode: '',
          mainOrgName: '',
          manageName: '',
          contactInfo: '',
        },
        showDrawer: false,
        isEdit: false,
      };
    },
    created() {
      this.searchTableList();
    },
    computed: {
      drawerTitle() {
        return `投资方${this.isEdit ? '编辑' : '新增'}`;
      },
      searchData() {
        return [
          {
            comName: 'YkcInput',
            key: 'investorName',
            label: '投资方名称',
            placeholder: '请输入投资方名称',
          },
        ];
      },
    },
    methods: {
      /**
       * 获取勾选id
       * */
      selectionChange(selection, row) {
        console.log(selection, row);
        this.selectIds = selection.map(item => {
          return item.id;
        });
      },
      detailLink(row) {
        this.$router.push({
          path: '/operationCenter/investmentConfig/investmentManage/detail',
          query: {
            id: row.id,
          },
        });
      },
      /**
       * 删除
       * */
      remove(row) {
        YkcDialog({
          showTitle: false,
          showFooter: true,
          type: 'warning',
          dialogType: 'feedback',
          desc: '确定删除该投资方？',
          onCancel: dialogDone => {
            dialogDone(); // 隐藏弹窗
          },
          onConfirm: dialogDone => {
            investmentData.investDelete({ id: row.id }).then(() => {
              this.searchTableList();
              dialogDone(); // 隐藏弹窗
            });
          },
        });
      },
      /**
       * 新增
       * */
      add() {
        this.maxStepIndex = 1;
        this.stepsIndex = 0;
        this.showAddDrawer = true;
        this.isEdit = false;
        this.id = 0;
        this.drawerType = 'add';
        this.showDrawer = true;
      },
      handleSpecialCountClick(row) {
        const routeUrl = this.$router.resolve({
          path: '/priceConfig/specialPurchasePrice',
          query: {
            stationId: row.stationId,
          },
        });
        window.open(routeUrl.href, '_blank');
      },
      changeStateSearch() {
        this.pageInfo.current = 1;
        this.searchTableList();
      },
      /** 根据条件查询数据 */
      searchTableList() {
        Promise.all([
          investmentData.investmentList({
            ...this.pageInfo,
            ...this.searchParams,
          }),
        ]).then(([res1]) => {
          this.tableData = res1?.records;
          this.pageInfo.total = res1?.total || 0;
        });
      },
      /**
       * @desc 导出文件 - 车队主账号列表
       * */
      doExportFile() {
        console.log('doExportFile2');
        offlineExport(
          {
            bizType: 'operator',
            downloadType: 'operator',
            jsonNode: {
              downloadKey: 'merchant_operator_export',
              operatorType: '2', // 互联商户
              name: this.searchParams.name,
              socialCreditCode: this.searchParams.socialCreditCode,
              mainOrgName: this.searchParams.mainOrgName,
              manageName: this.searchParams.manageName,
              contactInfo: this.searchParams.contactInfo,
            },
          },
          this.pageInfo.total
        );
      },
      /** 批量导入文件 */
      doImportFile() {
        this.stepsIndex = 0;
        this.maxStepIndex = 0;
        this.drawerType = 'import';
        this.isEdit = true;
        this.showDrawer = true;
      },
      onAddDrawerClose(done) {
        this.stepsIndex = 0;
        done();
      },
      /**
       * @desc 点击抽屉取消按钮 callback
       * */
      onAddDrawerCancel(drawerDone) {
        YkcDialog({
          dialogType: 'feedback',
          showTitle: false,
          showFooter: true,
          desc: '取消后操作将不被保存，确认是否取消？',
          onCancel: done => {
            done();
          },
          onConfirm: done => {
            done();
            drawerDone();
            if (this.isEdit) {
              this.isEdit = false;
            }
          },
        });
      },
      /**
       * @desc 点击页面左上角按钮 callback
       * */
      handlePageButtonClick(button, event) {
        if (typeof button.handleClick === 'function') {
          button.handleClick(button, event);
        }
      },
      /**
       * @desc 点击查询按钮
       * */
      handleSearchConfirm(form) {
        this.pageInfo.current = 1;
        Object.assign(this.searchParams, form, {
          operatorType: '2',
        });
        this.searchTableList();
      },
      /**
       * 点击清空查询按钮
       * */
      handleSearchClean(form) {
        Object.assign(this.searchParams, form);
      },
      /**
       * @desc 分页 change 事件监听器
       * */
      handlePaginationCurrentChange(current) {
        this.pageInfo.current = current;
        this.searchTableList();
      },
      /**
       * 分页 size  change  事件监听器
       * */
      handlePaginationSizeChange(size) {
        this.pageInfo.current = 1;
        this.pageInfo.size = size;
        this.searchTableList();
      },
    },
  };
</script>
<style lang="scss" scoped>
  .link {
    color: blue;
    cursor: pointer;
  }
  .el-radio-group .item {
    border: 0;
  }
</style>
