<!-- 分步骤add -->
<template>
  <ykc-form class="el-form-wrap">
    <!-- <el-steps :active="active" align-center finish-status="success" v-if="!isEdit">
      <el-step title="机构信息" />
      <el-step title="密钥信息" />
    </el-steps> -->
    <div>
      <ykc-form :model="ruleForm" :rules="rules" ref="ruleForm" class="el-form-wrap">
        <ykc-form-item label="投资方名称" prop="investorName">
          <ykc-input placeholder="请输入投资方名称" v-model="ruleForm.investorName"></ykc-input>
        </ykc-form-item>
        <ykc-form-item label="运营商ID" prop="operatorId">
          <ykc-input
            placeholder="请输入运营商ID"
            maxlength="50"
            v-model="ruleForm.operatorId"></ykc-input>
        </ykc-form-item>
        <ykc-form-item label="设备所属⽅ID" prop="deviceId">
          <ykc-input
            placeholder="请输入设备所属⽅ID"
            maxlength="50"
            v-model="ruleForm.deviceId"></ykc-input>
        </ykc-form-item>
        <ykc-form-item label="开票名称" prop="invoicingName">
          <ykc-input
            placeholder="请输入开票名称"
            maxlength="50"
            v-model="ruleForm.invoicingName"></ykc-input>
        </ykc-form-item>
        <ykc-form-item label="联系⼈" prop="contacts">
          <ykc-input
            placeholder="请输入联系⼈"
            maxlength="50"
            v-model="ruleForm.contacts"></ykc-input>
        </ykc-form-item>
        <ykc-form-item label="联系电话" prop="contactsPhone">
          <ykc-input
            placeholder="请输入联系电话"
            maxlength="11"
            v-model="ruleForm.contactsPhone"></ykc-input>
        </ykc-form-item>
        <ykc-form-item label="公司地址" prop="companyAddress">
          <ykc-input
            placeholder="请输入公司地址"
            maxlength="100"
            v-model="ruleForm.companyAddress"></ykc-input>
        </ykc-form-item>
        <ykc-form-item label="公司电话" prop="companyPhone">
          <ykc-input
            placeholder="请输入公司电话"
            maxlength="11"
            v-model="ruleForm.companyPhone"></ykc-input>
        </ykc-form-item>
        <ykc-form-item label="收款银⾏" prop="bank">
          <ykc-input
            placeholder="请输入收款银⾏"
            maxlength="50"
            v-model="ruleForm.bank"></ykc-input>
        </ykc-form-item>
        <ykc-form-item label="收款账号" prop="bankNo">
          <ykc-input
            placeholder="请输入收款账号"
            maxlength="50"
            v-model="ruleForm.bankNo"></ykc-input>
        </ykc-form-item>
        <ykc-form-item label="开票地址" prop="invoicingAddress">
          <ykc-input
            placeholder="请输入开票地址"
            maxlength="100"
            v-model="ruleForm.invoicingAddress"></ykc-input>
        </ykc-form-item>
        <ykc-form-item label="税号" prop="taxNo">
          <ykc-input placeholder="请输入税号" maxlength="50" v-model="ruleForm.taxNo"></ykc-input>
        </ykc-form-item>
        <ykc-form-item label="微信商户" prop="mchCode">
          <ykc-dropdown v-model="ruleForm.mchCode" :data="mchCodeData"></ykc-dropdown>
        </ykc-form-item>
      </ykc-form>
    </div>
  </ykc-form>
</template>
<script>
  // import regexpObj from '@/utils/regexp';
  import { investmentData } from '@/service/apis';

  export default {
    props: {
      isEdit: {
        type: Boolean,
        default: false,
      },
      id: {
        type: Number,
        default: () => 0,
      },
    },
    name: 'AddOrEdit',
    data() {
      return {
        detail: {}, // 详情数据
        dictionary: {},
        organizations: [],
        mchCodeData: [],
        pictureArr: [
          {
            name: '上传图片',
            url: '',
          },
        ],
        ruleForm: {
          // operatorType: '2', // 互联商户
          id: '',
          investorName: '',
          operatorId: '91310115088669285W',
          deviceId: '',
          invoicingName: '',
          bank: '',
          bankNo: '',
          companyAddress: '',
          companyPhone: '',
          contacts: '',
          contactsPhone: '',
          invoicingAddress: '',
          taxNo: '',
        },
        rules: {
          investorName: [
            { required: true, message: '请输入投资方名称', trigger: 'blur' },
            // {
            //   trigger: 'blur',
            //   pattern: regexpObj.regexp.input.nameType.regexp,
            //   message: regexpObj.regexp.input.nameType.errorTips.error('机构名称'),
            // },
          ],
          deviceId: [
            { required: true, message: '请输入设备所属⽅ID', trigger: 'blur' },
            // {
            //   trigger: 'blur',
            //   pattern: regexpObj.regexp.input.nameType.regexp,
            //   message: regexpObj.regexp.input.nameType.errorTips.error('公司名称'),
            // },
          ],
          // socialCreditCode: [
          //   { required: true, message: '请输入18位社会统一信用代码', trigger: 'blur' },
          //   {
          //     trigger: 'blur',
          //     pattern: regexpObj.regexp.input.creditCode.regexp,
          //     message: regexpObj.regexp.input.creditCode.errorTips.error('社会统一信用代码'),
          //   },
          // ],
          contacts: [
            { required: true, message: '请输入联系人', trigger: 'blur' },
            // {
            //   trigger: 'blur',
            //   pattern: regexpObj.regexp.input.nameType.regexp,
            //   message: regexpObj.regexp.input.nameType.errorTips.error('机构管理员姓名'),
            // },
          ],
          // contactInfo: [
          //   { required: true, message: '请输入机构管理员联系电话', trigger: 'change' },
          //   {
          //     trigger: 'blur',
          //     pattern: regexpObj.regexp.input.cellphoneType.regexp,
          //     message: regexpObj.regexp.input.cellphoneType.errorTips.error('机构管理员联系电话'),
          //   },
          // ],
          mchCode: [{ required: true, message: '请选择微信商户', trigger: 'change' }],
          // dataEncryptionKey: [{ required: false, message: '请输入消息秘钥', trigger: 'change' }],
          // initializationVector: [
          //   { required: false, message: '请输入初始化向量（16位数字、字母）', trigger: 'change' },
          //   {
          //     trigger: 'blur',
          //     pattern: regexpObj.regexp.input.inlineTextType_16.regexp,
          //     message: regexpObj.regexp.input.inlineTextType_16.errorTips.error('初始化向量'),
          //   },
          // ],
          // signKey: [{ required: false, message: '请输入签名密钥', trigger: 'change' }],
          // interconnectionUrl: [{ required: false, message: '请输入接口Url', trigger: 'change' }],
        },
      };
    },
    created() {
      // 编辑回显
      if (this.id) {
        investmentData
          .investmentDetail({ id: this.id })
          .then(res => {
            this.ruleForm = {
              ...this.ruleForm,
              ...res,
            };
          })
          .catch(() => {});
      }
      this.requestAllmc();
    },
    computed: {
      formData() {
        const formData = {
          ...this.ruleForm,
        };
        if (this.id) {
          formData.id = this.id;
        }
        return formData;
      },
    },
    methods: {
      /** 微信商户 */
      requestAllmc() {
        investmentData.wxMch({}).then(res => {
          console.log(res);
          res?.map(item =>
            this.mchCodeData.push({
              id: item.code,
              name: item.name,
            })
          );
        });
      },
      /** 表单提交事件，用于父组件定义的"完成"或"保存"按钮点击事件 */
      submitForm() {
        return new Promise(resolve => {
          this.validateAllForm().then(() => {
            if (!this.id) {
              investmentData
                .saveOrUpdate(this.formData)
                .then(() => {
                  this.$message({
                    message: '保存成功',
                    type: 'success',
                  });
                  resolve();
                })
                .catch(() => {});
            } else {
              // console.log('编辑', this.formData);
              investmentData
                .saveOrUpdate(this.formData)
                .then(() => {
                  this.$message({
                    message: '保存成功',
                    type: 'success',
                  });
                  resolve();
                })
                .catch(() => {});
            }
          });
        });
      },
      /** 校验当前表格是否通过 */
      validateForm() {
        return new Promise((resolve, reject) => {
          this.$refs.ruleForm.validate(valid => {
            if (valid) {
              resolve();
            } else {
              reject();
            }
          });
        });
      },
      /** 校验所有表格是否通过 */
      validateAllForm() {
        return new Promise((resolve, reject) => {
          Promise.all(
            Object.keys(this.$refs)
              .filter(o => o.includes('ruleForm'))
              .map(o => {
                return new Promise((_resolve, _reject) => {
                  this.$refs[o].validate(valid => {
                    if (valid) {
                      _resolve();
                    } else {
                      _reject();
                    }
                  });
                });
              })
          )
            .then(() => {
              resolve();
            })
            .catch(() => {
              reject();
            });
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .upload-img-box {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
</style>
